import { gql } from "@apollo/client";
export const DYNAMIC_FORM_FIELDS = gql`
  query dynamic($id: String, $formId: String) {
    dynamic(id: $id, formId: $formId) {
      _id
      title
      description
      formId
      isPayment
      redirectUrl
      submissionType
      isHeadingVisible
      preference{
        bgColor
        fontColor
        fontSize
        ctaColor
        ctaFontColor
      }
      questions {
        _id
        type
        title
        value
        highlight
        onChange
        visible{
          key
          value
        }
        required
        isFeatured
        isPrice
        additional{
          value
          image_url
          price
          isControl
          isQuantity
        }
        options
        multipleOptions {
          label
          value
        }
        option {
          dataFetch
          values
          source {
            schema

            condition {
              key
              value
            }
          }
        }
        minValue
        maxValue
      }
      preference{
        bgColor
        fontColor
        fontSize
        ctaColor
        ctaFontColor
      }
      payment
      gid
      isGST{
        status
        amount
      }
      gidData {
        _id
        gatewayName
        gatewaySdk
        key
        secret
        channelId
        industrialType
        website
        status
      }
      isGST {
        status
        amount
      }
      amount {
        title
        isManual
        isMulti
        options {
          _id
          amount
          description
        }
      }
      members
     
      status
    }
  }
`;

export const RESPONSES = gql`
  query responses(
    $formId: String
    $userId: String
    $phone: String
    $checkSubmissionType: Boolean
  ) {
    responses(
      formId: $formId
      userId: $userId
      phone: $phone
      checkSubmissionType: $checkSubmissionType
    ) {
      _id
      formId
      userId
      paymentStatus
      amountId
      amountIds
      formData {
        _id
        title
        formId
      }
      userData {
        _id
        firstName
        lastName
        email
        phone
        picUrl
        fcmToken
        userType
        barCode
        code
        signature
        otherRoles
        status
        createdAt
        updatedAt
      }
      responses {
        _id
        answer
      }
      paymentStatus
      amount
      transId
      transData {
        _id
        invoice
        mode
        tranactionType
        depositId
        amount
        enterDate
        settledDate
        customerId
        customer
        confirmId
        description
        module
        document
        refundDate
        status
      }
      status
    }
  }
`;

export const LIST_DYNAMIC_FORMS = gql`
  query listDynamicWithCursor(
    $filter: DynamicFilterInput
    $args: ConnectionArgs!
  ) {
    listDynamicWithCursor(filter: $filter, args: $args) {
      page {
        edges {
          cursor
          node {
            _id
            title
            description
            formId
            questions {
              _id
              type
              title
              highlight
              required
              value
              options
              multipleOptions {
                label
                value
              }
              option {
                dataFetch
                values
                source {
                  schema
                  condition {
                    key
                    value
                  }
                }
              }
              minValue
              maxValue
            }
            payment
            gid
            gidData {
              _id
              gatewayName
              gatewaySdk
              key
              secret
              channelId
              industrialType
              website
              status
            }
            isGST {
              status
              amount
            }
            amount {
              title
              options {
                _id
                amount
                description
              }
            }
            members
            status
          }
        }
        pageInfo {
          startCursor
          endCursor
          hasPreviousPage
          hasNextPage
        }
      }
      pageData {
        count
        limit
        offset
      }
    }
  }
`;

